import React from 'react'
import QueryFormsContainer from './QueryFormsContainer'
import { H4 } from '../../../components'
import { FormattedMessage } from '../../../util/reactIntl'
import { array, func, object } from 'prop-types'
import { propTypes } from '../../../util/types'
import css from './QueryForm.module.css'
import searchImage from '../../../assets/searchImage.png'


const filterIndexedFields = (fields) => (
    fields && Array.isArray(fields) ?
        fields.filter(field => {
            const { filterConfig } = field;
            return filterConfig && filterConfig.indexForSearch;
        }) : []
)

export default function QuerySection(props) {
    const { onQuery, intl, listingFields, initialValues, resetAllFilters, ...rest } = props;
    const indexedFields = filterIndexedFields(listingFields);
    const querySectionTitle = intl.formatMessage({ id: "SearchPage.querySectionTitle" });
    const querySectionSubtitle = intl.formatMessage({ id: "SearchPage.querySectionSubtitle" });
    return (
        <div className={css.filterWrapper}>
            <div className={css.formWrapper}>
                <H4 className={css.searchTitle}>{querySectionTitle}</H4>
                <h5 className={css.subtitle}>{querySectionSubtitle}</h5>
                <div className={css.filterContents}>
                    <QueryFormsContainer indexedFields={indexedFields} onQuery={onQuery} intl={intl} onSubmit={() => { }} initialValues={initialValues} {...rest} />
                    <button className={css.resetButton} onClick={resetAllFilters}>
                        <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
                    </button>
                </div>
            </div>
            <div className={css.searchImage}>
                <img src={searchImage} alt='search image' />
            </div>
        </div>
    )
}

QuerySection.prototype = {
    onQuery: func.isRequired,
    listingFields: array.isRequired,
    initialValues: object.isRequired,
    resetAllFilters: func.isRequired
}