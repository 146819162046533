import React, { useState } from 'react'
import { Field, Form as FinalForm, FormSpy } from 'react-final-form'
import { Button, FieldDateRangeInput, FieldRadioButton, FieldSelect, Form, LocationAutocompleteInput } from '../../../components'
import { CiSearch } from "react-icons/ci";
import { stringifyDateToISO8601 } from '../../../util/dates';
import moment from 'moment';
import css from './QueryForm.module.css';
import { isOriginInUse } from '../../../util/search';
import Select from 'react-select'
import { GP, HOSPITAL_DOCTOR, USER_TYPE_DOCTOR, USER_TYPE_ORGANISATIONS } from '../../../util/types';

// import * as validators from '../../../util/validators';

const formatValue = (dateRange, queryParamName) => {
    const hasDates = dateRange && dateRange.dates;
    const { startDate, endDate } = hasDates ? dateRange.dates : {};
    const start = startDate ? stringifyDateToISO8601(startDate) : null;
    const end = endDate ? stringifyDateToISO8601(endDate) : null;
    const value = start && end ? `${start},${end}` : null;
    return { [queryParamName]: value };
};


const FieldSearchInput = (props) => (
    <Field form={null} {...props}>
        {fieldprops => {
            const { input, placeholder } = fieldprops;
            const { name, type, ...restInputs } = input;
            const inputProps = { id: name, name, type, placeholder, ...restInputs };
            return (
                <>
                    <div className={css.searchInput}>
                        <label htmlFor={name}>
                            <CiSearch style={{ fontSize: '24px', position: 'absolute', top: '10px', left: '10px' }} />
                        </label>
                        <input {...inputProps} style={{ paddingLeft: '32px' }} />
                    </div>
                </>
            );
        }}
    </Field>
)



const CategoryForm = (props) => {
    return (
        <FinalForm
            {...props}
            render={formRenderProps => {
                const {
                    intl,
                    onQuery
                } = formRenderProps;
                const name = "pub_category";
                const categoryChangeHandler = ({ values, dirty }) => dirty ? onQuery({ [name]: values[name] }) : null;
                return (
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <FormSpy subscription={{ values: true, dirty: true }} onChange={categoryChangeHandler} />
                        <div className={css.radioWrapper}>
                            <FieldRadioButton
                                id="category-GP"
                                name={name}
                                label="G.P"
                                value={GP}
                            />
                            <FieldRadioButton
                                id="category-doctor"
                                name={name}
                                label="Hospital Doctor"
                                value={HOSPITAL_DOCTOR}
                            />
                        </div>
                    </Form>
                )
            }}
        />
    )
}

const DateRageForm = (props) => {
    return (
        <FinalForm
            {...props}
            render={formRenderProps => {
                const {
                    intl,
                    onQuery
                } = formRenderProps;
                const queryStartDateLabel = intl.formatMessage({ id: 'SearchPage.queryFormQueryStartDateLabel' });
                const startDatePlaceholderText = intl.formatMessage({ id: 'SearchPage.queryFormQueryStartDatePlaceholderText' });
                const queryEndDateLabel = intl.formatMessage({ id: 'SearchPage.queryFormQueryEndDateLabel' });
                const queryEndDatePlaceholderText = intl.formatMessage({ id: 'SearchPage.queryFormQueryEndDatePlaceholderText' });

                const dateRangeChangeHandler = ({ values, dirty }) => {
                    const { dates } = formatValue(values, 'dates');
                    if (dates && dirty) {
                        onQuery({ dates });
                    }
                }

                const isOutsideRange = () => (day) => moment(day).isBefore(moment().subtract(1, 'day'));
                return (
                    <Form onSubmit={(e) => e.preventDefault()} className={css.dateRangeBox}>
                        <FormSpy subscription={{ values: true, dirty: true }} onChange={dateRangeChangeHandler} />
                        <FieldDateRangeInput
                            name='dates'
                            isDaily={false}
                            startDateId='postStartDate'
                            startDateLabel={queryStartDateLabel}
                            startDatePlaceholderText={startDatePlaceholderText}
                            endDateId="postEndDate"
                            endDateLabel={queryEndDateLabel}
                            endDatePlaceholderText={queryEndDatePlaceholderText}
                            format={(v) => v}
                            onBlur={() => null}
                            onFocus={() => null}
                            isBlockedBetween={() => () => false}
                            isDayBlocked={() => () => false}
                            isOutsideRange={isOutsideRange}
                            className={css.dateInputs}
                        />
                    </Form>
                )

            }}
        />
    )
}

const SearchForm = (props) => {
    return (
        <FinalForm
            {...props}
            render={formRenderProps => {
                const [timer, setTimer] = useState(null);
                const {
                    form: formApi,
                    intl,
                    onQuery,
                    values,
                    role
                } = formRenderProps;

                const keywordInputPlaceholder = intl.formatMessage({ id: 'SearchPage.queryFormKeywordInputPlaceholder' });
                // const rolePlaceholder = intl.formatMessage({ id: 'SearchPage.queryFormRolePlaceholder' });
                // const roleDoctor = intl.formatMessage({ id: 'SearchPage.queryFormRoleDoctor' });
                // const roleOrg = intl.formatMessage({ id: 'SearchPage.queryFormRoleOrg' });

                // const searchFormChangeHandler = ({ values, dirty }) => {
                //     if (values && dirty) {
                //         if (timer) {
                //             clearInterval(timer)
                //         }
                //         const keywordsMaybe = !!values.keywords ? { keywords: values.keywords } : { keywords: null };
                //         const roleMaybe = !!values.pub_userRole ? { pub_userRole: values.pub_userRole } : {};
                //         const queryTimer = setTimeout(() => {
                //             onQuery({ ...keywordsMaybe, ...roleMaybe })
                //         }, 500);
                //         setTimer(queryTimer);
                //     }
                // };


                const searchButtonText = intl.formatMessage({ id: "SearchPage.queryFormSearchButtonText" }, { role:'doctors' });
                const searchFormSubmitHandler = (e) => {
                    e.preventDefault();
                    const keywordsMaybe = !!values.keywords ? { keywords: values.keywords } : { keywords: null };
                    onQuery({ ...keywordsMaybe })
                };

                return (
                    <Form onSubmit={searchFormSubmitHandler} >
                        {/* <div className={css.searchContents}>
                            <FormSpy subscription={{ values: true, dirty: true }} onChange={searchFormChangeHandler} />
                            <FieldSearchInput name="keywords" type="text" placeholder={keywordInputPlaceholder} />
                            <FieldSelect className={css.searchSelect} id="jobOrDoctor" name="pub_userRole">
                                <option disabled value="">{rolePlaceholder}</option>
                                <option value={USER_TYPE_DOCTOR}>{roleDoctor}</option>
                                <option value={USER_TYPE_ORGANISATIONS} >{roleOrg}</option>
                            </FieldSelect>
                        </div> */}
                        <div className={css.searchContents}>
                            <FieldSearchInput name="keywords" type="text" placeholder={keywordInputPlaceholder} />
                            <Button className={css.searchButton} type="submit">{searchButtonText}</Button>
                        </div>
                    </Form>
                )
            }}
        />
    )
}

const createFilterOptions = options => options.map(o => ({ value: `${o.option}`, label: o.label }));

const selectFilters = (indexedFields, placeholder) => {
    return indexedFields && indexedFields.length ?
        indexedFields.map(field => {
            const { enumOptions, key, scope, filterConfig: { label: fieldLabel }, schemaType } = field;
            const filterOptions = createFilterOptions(enumOptions);
            const uniqueKey = key;
            const label = fieldLabel ? fieldLabel.split(' ')[1] : '';
            return schemaType === 'enum' ? (
                <FieldSelect className={css.doctorSelect} id={uniqueKey} name={uniqueKey} label={label} key={uniqueKey}>
                    <option value="" disabled>{placeholder}</option>
                    {filterOptions.map(opt => (
                        <option value={opt.value} key={opt.value}>{opt.label}</option>
                    ))}
                </FieldSelect>
            ) : schemaType === 'multi-enum' ? (
                <div className={css.inputBox} key={uniqueKey}>
                    <label htmlFor={uniqueKey}>{label}</label>
                    <Field name={uniqueKey} component="select">
                        {({ input }) => (
                            <Select
                                {...input}
                                options={filterOptions}
                                isMulti={true}
                                className={css.multiSelectBox}
                            />
                        )}
                    </Field>
                </div>
            ) : null;
        }) : null
}

const SelectFiltersForm = (props) => {
    return (
        <FinalForm
            {...props}
            render={formRenderProps => {
                const {
                    intl,
                    onQuery,
                    indexedFields,
                    values
                } = formRenderProps;
                const selectFilersPlaceholder = intl.formatMessage({ id: 'SearchPage.queryFormSelectFiltersPlaceholder' });
                const filterChangeHandler = (v) => {
                    let filteredObj = {};
                    for (let key in v.values) {
                        let indexedF = indexedFields.find(f => f.key === key);
                        const { scope, schemaType } = indexedF || {};
                        if (indexedF) {
                            const prefix = scope === 'public' ? 'pub_' : 'priv_';
                            const value = schemaType === 'enum'
                                ? v.values[key]
                                : schemaType === 'multi-enum' && v.values[key].length
                                    ? `has_any:${v.values[key].map(v => v.value).join(', ')}`
                                    : null;
                            filteredObj[`${prefix}${key}`] = value;
                        }
                    }
                    if (Object.keys(filteredObj).length && v.dirty) {
                        onQuery(filteredObj)
                    }
                }
                return (
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <FormSpy subscription={{ values: true, dirty: true }} onChange={filterChangeHandler} />
                        <div className={css.doctorSection}>
                            {selectFilters(indexedFields, selectFilersPlaceholder)}
                        </div>
                    </Form>
                )
            }}
        />
    )
}

const identity = v => v;
const LocationForm = (props) => (
    <FinalForm
        {...props}
        render={formRenderProps => {
            const { handleSubmit, intl } = formRenderProps;
            const locationFormLable = intl.formatMessage({ id: 'SearchPage.queryFormLocationFieldPlaceholder' });
            const locationFormSubmit = intl.formatMessage({ id: 'SearchPage.queryFormLocationFieldApply' });
            return (
                <Form onSubmit={handleSubmit} className={css.locationInput}>
                    <div className={css.inputContent}>
                        <label htmlFor="location">{locationFormLable}</label>
                        <Field placeholder="Location" name="location" format={identity} component={LocationAutocompleteInput} />
                    </div>
                    <Button type="submit" disabled={false}>
                        {locationFormSubmit}
                    </Button>
                </Form>
            )
        }}
    />
)


export default function QueryFormsContainer(props) {
    const { intl, onQuery, indexedFields, initialValues, ...rest } = props;
    const { params = {} } = rest || {};
    const { role } = params;

    const emptySubmit = () => null;
    const { pub_category, pub_userRole } = initialValues || {};
  
    const locationFilterHandler = (values) => {
        const { selectedPlace = {} } = values?.location || {};
        const { bounds } = selectedPlace;

        const locationParams = {
            bounds,
        };
        onQuery(locationParams);
    }

    const filtersInitialValues = {};
    for (let key in initialValues) {
        const updateKey = key.slice(4);
        const indexedF = indexedFields.find(indexF => indexF.key === updateKey);
        const { enumOptions, schemaType } = indexedF || {};
        if (indexedF && schemaType === 'enum') {
            filtersInitialValues[updateKey] = initialValues[key];
        }
        if (indexedF && schemaType === 'multi-enum') {
            const filterValsArr = initialValues[key].slice(8).split(',');
            const updatedFilterValsArr = filterValsArr.map(v => {
                const option = enumOptions.find(opt => opt.option === v);
                return { value: option.option, label: option.label }
            })
            filtersInitialValues[updateKey] = updatedFilterValsArr;

        }
    };

    return (
        <div>
            <CategoryForm intl={intl} onQuery={onQuery} onSubmit={emptySubmit} initialValues={initialValues} />
            {pub_category === HOSPITAL_DOCTOR
                ? <SelectFiltersForm
                    intl={intl}
                    indexedFields={indexedFields}
                    onQuery={onQuery}
                    onSubmit={emptySubmit}
                    initialValues={filtersInitialValues}
                /> : null}
            {/* {pub_userRole === USER_TYPE_DOCTOR
                ? <DateRageForm
                    intl={intl}
                    onQuery={onQuery}
                    onSubmit={emptySubmit}
                    initialValues={initialValues}
                /> : null} */}
            <LocationForm onSubmit={locationFilterHandler} intl={intl} />
            <SearchForm intl={intl} onQuery={onQuery} onSubmit={emptySubmit} initialValues={initialValues} role={role} />
        </div>
    )
}
